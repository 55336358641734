import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {
  Autocomplete,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  debounce,
  Tooltip,
  // Paper,
  // Divider,
  // Checkbox,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TimePicker from "react-time-picker";
import MDTypography from "components/MDTypography";
import AlertConfirm from "components/AlertConfirm";
import FreeGameForm from "./FreeGameForm";
// import MultiSelect from "./MultiSelect";
import MultiSelect from "../../../components/game/MultiSelect";
import * as apiService from "../../../services/api/gameAPI";
// import { ContentPasteSearchOutlined } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import GameForm from "../../../components/game/Form";

const style = {
  position: "absolute",
  top: { xs: "50%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 700, lg: 800 },
  height: { xs: 300, sm: 350, md: 450, lg: 550 },
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
};

function GameCreate() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [gameConfig, setGameConfig] = useState(null);
  const [data, setData] = useState([]);
  const [cryptos, setCryptos] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [startTimeError, setStartTimeError] = useState(null);
  const [gameData, setGameData] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [error, setError] = useState({ value: "", positions: "" }); //for cryptos

  const [selectedOption, setSelectedOption] = useState("paid");

  //
  const [minPlayer, setMinPlayer] = useState(0);
  const [maxPlayer, setMaxPlayer] = useState(0);
  const [prizeMoney, setPrizeMoney] = useState(0);
  const [entryFees, setEntryFees] = useState();
  const [userLimit, setUserLimit] = useState(0);
  const [contestInterval, setContestInterval] = useState(0);
  const [gameDuration, setGameDuration] = useState(0);

  const [profitPercentage, setProfitPercentage] = useState(0);

  const [fupApiError, setFupApiError] = useState(null);
  const [contestIntervalError, setContestIntervalError] = useState(null);

  const { handleSubmit, control, setValue, getValues, unregister } = useForm({
    // resolver: yupResolver(ProductSchema),
  });

  useEffect(() => {
    const fetchData = async () => {
      if (open) {
        try {
          const cryptosData = await apiService.getCryptos();
          const gameConfigData = await apiService.getGameConfig();
          const config = await apiService.getBlockChianConfig({});
          // console.log(config?.data?.adminProfitPercentage);
          setProfitPercentage(config?.data?.adminProfitPercentage);
          setCryptos(cryptosData.data.data);
          setGameConfig(gameConfigData.data.data);

          // setValue("gameAssetType", gameConfigData?.data?.data?.gameAssetType[0] ?? "");
        } catch (error) {
          // Handle errors here
          console.error("Error fetching data:", error);
        }
      }
    };

    const fetchStocks = async () => {
      const _stocks = await apiService.getStocks();
      setStocks(_stocks.data.data);
    };

    fetchData();
    fetchStocks();
  }, [open]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const CreateGame = (_gameData) => {
    console.log(_gameData?.stocks);
    const isAnyPrizeNull = data.some((item) => item.prize === undefined || item.prize === "");

    if (_gameData?.gameAssetType == "CRYPTOS") {
      if ((_gameData?.cryptos?.length ?? 0) < _gameData.gameType) {
        setError({
          ...error,
          value: `Please select atleast ${_gameData.gameType} cryptos to continue `,
        });
        return;
      }
    } else {
      if ((_gameData?.stocks?.length ?? 0) < _gameData.gameType) {
        setError({
          ...error,
          value: `Please select atleast ${_gameData.gameType} stocks to continue `,
        });
        return;
      }
    }

    if (isAnyPrizeNull) {
      setError({ ...error, positions: "Please fill all the prize values" });
      console.log("One or more prize values are null or empty. Cannot create game.");
      return; // Do not proceed further
    }

    setGameData(_gameData);
    setAlertOpen(true);
  };

  useEffect(() => {
    const handleCalculatePrizeMoney = async () => {
      // const totalAmount = entryFees * minPlayer;
      // const totalPrize = entryFees * minPlayer * 0.2;
      // setPrizeMoney(totalAmount - totalPrize);

      const totalAmount = entryFees * minPlayer;
      const totalPrize = entryFees * minPlayer * (parseInt(profitPercentage ?? 0) / 100);
      setPrizeMoney(totalAmount - totalPrize);
      // }
    };
    handleCalculatePrizeMoney();
  }, [entryFees, minPlayer]);

  // console.log(cryptos, "cryptos");

  // Debounce the API call to avoid making API calls on every keystroke
  const debouncedCheckUserAvailability = useCallback(
    debounce((per, minPlayer) => {
      handleUserAvailability(per, minPlayer);
    }, 300),
    [] // The empty array ensures the debounce function is created only once
  );

  const handleUserAvailability = async (per, minPlayer) => {
    try {
      setFupApiError(null);

      if (Number(per) <= 0) return;
      if (!per) return false;

      const response = await apiService.calCustomUser({
        enteredPercentage: parseInt(per),
        minPlayer: minPlayer,
      });
      const data = response.data;
      console.log(data);
      if (data?.data?.available == false) {
        setFupApiError("Not have enough users to add");
        return false;
      } else {
        setFupApiError(null);
        return true;
      }
    } catch (error) {
      console.error("API call failed:", error);
      setFupApiError("Failed to check user availability");
      return false;
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained">
        Create Game
      </Button>

      {/* GAME CREATE MODEL DESIGN */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  padding: 3,
                }}
              >
                {/* TITLE */}
                <MDTypography
                  variant="h5"
                  color="white"
                  p={1.5}
                  sx={{ textAlign: "center", marginTop: "-21px" }}
                >
                  Create Game
                </MDTypography>

                {/* GAME PAYMENT TYPE - [FREE OR PAID] */}
                <Box display={"flex"} alignItems={"center"} gap={4} marginBottom={2}>
                  <Typography variant="h6" gutterBottom>
                    Payment Type :
                  </Typography>

                  <RadioGroup
                    row
                    aria-label="form-type"
                    name="form-type"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <FormControlLabel value="paid" control={<Radio />} label="Paid" />
                    <FormControlLabel value="free" control={<Radio />} label="Free" />
                  </RadioGroup>
                </Box>

                {/* FREE GAME */}
                {selectedOption === "free" && (
                  <>
                    <FreeGameForm
                      gameConfig={gameConfig}
                      cryptos={cryptos}
                      setOpen={setOpen}
                      stocks={stocks}
                    />
                  </>
                )}

                {/* PAID GAME */}
                {selectedOption === "paid" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                      padding: 3,
                      flexWrap: "wrap",
                      flexDirection: "column",
                      mt: -5,
                    }}
                    component="form"
                    onSubmit={handleSubmit(CreateGame)}
                  >
                    {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      <Controller
                        name="gameName"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Game name is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            id="outlined-basic"
                            label="Game Name"
                            value={value}
                            onChange={onChange}
                            variant="outlined"
                            error={!!error}
                            helperText={error ? error.message : null}
                            autoComplete="off"
                          />
                        )}
                      />
                      <Controller
                        name="gameCategory"
                        control={control}
                        defaultValue={"Multiplayer"}
                        rules={{ required: "Game category is required" }}
                        render={({ field: { value }, fieldState: { error } }) => (
                          <TextField
                            id="outlined-basic"
                            label="Game category"
                            value={"Multiplayer"}
                            // onChange={onChange}
                            variant="outlined"
                            error={!!error}
                            // disabled
                            helperText={error ? error.message : null}
                            autoComplete="off"
                          />
                        )}
                      />

                      {/* {gameCategory ? ( */}
                      <Controller
                        name="payoutStructure"
                        control={control}
                        defaultValue={""}
                        rules={{ required: "Payout Structure is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: 180 }}
                            options={gameConfig?.payout ?? []}
                            getOptionLabel={(option) =>
                              (option?.name ? `${option?.name ?? ""} - ` : "") +
                              " Top " +
                              option?.minPlayer
                            }
                            onChange={(event, newValue) => {
                              onChange(newValue?.id || "");
                              // Use newValue instead of value
                              setMinPlayer(parseInt(newValue?.minPlayer ?? 0));
                              setMaxPlayer((newValue?.minPlayer ?? 0) * 2);
                              setFupApiError(null);
                              debouncedCheckUserAvailability(
                                userLimit,
                                parseInt(newValue?.minPlayer ?? 0)
                              );
                            }}
                            value={
                              value
                                ? gameConfig?.payout?.find((option) => value === option?.id)
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-basic"
                                label="Payout Structure"
                                variant="outlined"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: 45,
                                  },
                                }}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      {/* <Controller
                          name="maxPlayer"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Maximum players is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => ( */}
                      <TextField
                        id="outlined-basic"
                        label="Maximum Players"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={maxPlayer ?? 0}
                        // onChange={(e) => onChange(Number(e.target.value) || "")}
                        // error={!!error}
                        // helperText={error ? error.message : null}
                        // autoComplete="off"
                      />
                      {/* )}
                        /> */}
                      {/* <Controller
                          name="minPlayer"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "Minimum players is required" }}
                          render={({ field: { onChange }, fieldState: { error } }) => ( */}
                      <TextField
                        id="outlined-basic"
                        name="minPlayer"
                        label="Minimum Players"
                        type="number"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={minPlayer}
                        // onChange={(e) => onChange(e.target.value)}
                        // error={!!error}
                        // helperText={error ? error.message : null}
                        // autoComplete="off"
                      />
                      {/* )}
                        /> */}
                      <Controller
                        name="entryFees"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Entry fees is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            id="outlined-basic"
                            label="Entry Fees"
                            variant="outlined"
                            value={value}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              // Allow empty input or valid numbers including decimals
                              if (/^\d*\.?\d*$/.test(inputValue)) {
                                onChange(inputValue);
                                setEntryFees(inputValue);
                              }
                            }}
                            error={!!error}
                            helperText={error ? error.message : null}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "12px",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      {/* {gameCategory ? ( */}
                      <TextField
                        id="outlined-basic"
                        label="Prize Money"
                        variant="outlined"
                        // defaultValue={0}
                        value={`${!entryFees ? 0 : prizeMoney} `}
                        // onChange={(e) => onChange(Number(e.target.value) || "")}
                        // error={!!error}
                        // helperText={error ? error.message : null}
                        // autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      {/* ) : ( */}
                      {/* <Controller
                            name="prizeMoney"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Prize money is required" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-basic"
                                label="Prize Money"
                                variant="outlined"
                                value={prizeMoney}
                                onChange={(e) => onChange(Number(e.target.value) || "")}
                                error={!!error}
                                helperText={error ? error.message : null}
                                autoComplete="off"
                                // InputProps={{
                                //   readOnly: true,
                                // }}
                              />
                            )}
                          /> */}
                      {/* )} */}

                      <Controller
                        name="gameFrequency"
                        control={control}
                        defaultValue={undefined}
                        rules={{ required: "Game frequency is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: 180 }}
                            options={
                              gameConfig?.gameFrequency?.map((frequency) => ({
                                label: frequency,
                              })) || []
                            }
                            isOptionEqualToValue={(option, v) => option?.label === v?.label}
                            getOptionLabel={(option) => option?.label}
                            onChange={(event, newValue) => {
                              onChange(newValue?.label || ""); // Use newValue instead of value
                            }}
                            value={
                              value
                                ? gameConfig?.gameFrequency?.find(
                                    (option) => option?.label === value
                                  )
                                : undefined
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-basic"
                                label="Game Frequency"
                                variant="outlined"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: 45,
                                  },
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        )}
                      />

                      <Controller
                        name="gameDuration"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Entry fees is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            id="outlined-basic"
                            label="Game Duration"
                            variant="outlined"
                            value={value}
                            onChange={(e) => {
                              const interval = Number(e.target.value); //this will take only number
                              onChange(interval || "");
                              setGameDuration(interval);

                              if ((contestInterval ?? 0) < interval) {
                                setContestInterval(interval);
                                setValue("contestInterval", interval);
                                setContestIntervalError(null);
                              }
                            }}
                            error={!!error}
                            helperText={error ? error.message : null}
                            autoComplete="off"
                          />
                        )}
                      />

                      {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "18px",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      <Controller
                        name="gameType"
                        control={control}
                        defaultValue={undefined}
                        rules={{ required: "Game type is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: 180 }}
                            options={
                              gameConfig?.gameType?.map((type) => ({
                                label: type,
                              })) || []
                            }
                            isOptionEqualToValue={(option, v) => option?.label === v?.label} // Match options by label
                            getOptionLabel={(option) => `pick ${option.label}`}
                            onChange={(event, newValue) => {
                              onChange(Number(newValue?.label) || "");
                              //setting type value to state for validating the cryptos count
                            }}
                            value={
                              value
                                ? gameConfig?.gameType?.find((option) => option.label === value)
                                : undefined
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-basic"
                                label="Game Type"
                                variant="outlined"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: 45,
                                  },
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        )}
                      />
                      <Controller
                        name="winnerType"
                        control={control}
                        defaultValue={undefined}
                        rules={{ required: "Winner type is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: 180 }}
                            // getOptionLabel={(option: any) => option.product_name}
                            options={
                              gameConfig?.winnerType?.map((category) => ({
                                label: category,
                              })) || []
                            }
                            isOptionEqualToValue={(option, v) => option?.label === v?.label} // Match options by label
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                              onChange(newValue?.label || ""); // Use newValue instead of value
                            }}
                            value={
                              value
                                ? gameConfig?.winnerType?.find((option) => option.label === value)
                                : undefined
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-basic"
                                label="Winner Type"
                                variant="outlined"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: 45,
                                  },
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        )}
                      />

                      {/* {gameCategory ? ( */}
                      <Controller
                        name="gameGenerate"
                        control={control}
                        defaultValue={undefined}
                        rules={{ required: "Game generate is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: 180 }}
                            options={
                              gameConfig?.gameGenerate?.map((generate) => ({
                                label: generate,
                              })) || []
                            }
                            isOptionEqualToValue={(option, v) => option?.label === v?.label} // Match options by label
                            getOptionLabel={(option) => option?.label}
                            onChange={(event, newValue) => {
                              onChange(newValue?.label || ""); // Use newValue instead of value
                            }}
                            value={
                              value
                                ? gameConfig?.gameGenerate?.find(
                                    (option) => option?.label === value
                                  )
                                : undefined
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-basic"
                                label="Game Generate"
                                variant="outlined"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: 45,
                                  },
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        )}
                      />
                      {/* ) : (
                            ""
                          )} */}
                      {/* <Grid
                          container
                          display={"flex"}
                          alignItems={"center"}
                          flexDirection={"column"}
                        > */}

                      <Box
                        sx={{
                          display: "flex",
                          gap: "18px",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <InputLabel sx={{ marginBottom: -1 }}>
                            <Tooltip
                              title={
                                "Automatically fills with fake users if actual user participation is below the specified threshold percentage."
                              }
                              placement="top"
                            >
                              <InfoIcon sx={{ fontSize: 20, cursor: "pointer" }} />
                            </Tooltip>
                            {`    User Threshold Auto-Fill`}

                            {userLimit == 0 && (
                              <>
                                <br />
                                <Typography fontSize={12} sx={{ ml: 2 }}>
                                  {"   Disable Bot Users"}
                                </Typography>
                              </>
                            )}

                            {userLimit >= 100 && (
                              <>
                                <br />
                                <Typography fontSize={12} sx={{ ml: 2 }}>
                                  {"Start the game using bot users"}
                                </Typography>
                              </>
                            )}
                          </InputLabel>
                          <Controller
                            name="fup"
                            control={control}
                            defaultValue={0}
                            rules={{
                              required: "User Threshold Auto-Fill percentage is required",
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <FormControl>
                                <TextField
                                  sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                      {
                                        display: "none",
                                      },
                                    "& input[type=number]": {
                                      MozAppearance: "textfield",
                                    },
                                    marginTop: 2,
                                    width: 170,
                                  }}
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={value}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography sx={{ color: "#FFFFFF" }}>%</Typography>
                                      </InputAdornment>
                                    ),
                                    inputProps: {
                                      max: 100,
                                      min: userLimit == 0 ? 0 : 50,
                                    },
                                  }}
                                  type="number"
                                  defaultValue={0}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // Allow empty input or valid numbers including decimals
                                    if (/^\d*\.?\d*$/.test(inputValue)) {
                                      onChange(inputValue);
                                      setUserLimit(inputValue);
                                      console.log(parseInt(e?.target?.value ?? 0));

                                      if (parseInt(e?.target?.value ?? 0) >= 50) {
                                        debouncedCheckUserAvailability(inputValue, minPlayer);
                                      } else {
                                        if (parseInt(e?.target?.value ?? 0) != 0) {
                                          setFupApiError(
                                            "Value must be greater than or equal to 50"
                                          );
                                        } else {
                                          setFupApiError(null);
                                        }
                                      }
                                    }
                                  }}
                                  error={!!error || !!fupApiError}
                                  helperText={error ? error?.message : fupApiError ?? null}
                                  autoComplete="off"
                                />
                              </FormControl>
                            )}
                          />
                        </Box>

                        <Box
                          sx={{
                            marginTop: userLimit == 0 || userLimit >= 100 ? 3.8 : 2,
                            // height: "2rem",
                            // alignItems: "end",
                          }}
                        >
                          <Controller
                            name="startTime"
                            control={control}
                            rules={{ required: "Start time is required" }}
                            defaultValue={null}
                            render={({ field, fieldState: { error } }) => (
                              <FormControl>
                                <InputLabel sx={{ mt: -3 }}>Start Time</InputLabel>
                                <TimePicker
                                  onChange={(value) => {
                                    field.onChange(value);
                                    setStartTimeError(null); // Reset the error state on value change
                                  }}
                                  value={field.value}
                                  disableClock
                                  clearIcon={false}
                                  format="h:m a"
                                  hourPlaceholder="-"
                                  //

                                  className={`custom-timepicker ${error ? "error" : ""}`}
                                />

                                <FormHelperText error={!!error} id="my-helper-text">
                                  {error?.message}
                                </FormHelperText>
                              </FormControl>
                            )}
                          />
                        </Box>

                        <Box
                          sx={{
                            marginTop: userLimit == 0 || userLimit >= 100 ? 5 : 3,
                          }}
                        >
                          <Controller
                            name="contestInterval"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Contest interval is required" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-basic"
                                label="Contest Interval"
                                variant="outlined"
                                value={value || contestInterval || ""}
                                type="number"
                                onChange={(e) => {
                                  const interval = Number(e.target.value); //this will take only number
                                  onChange(interval || "");
                                  setContestInterval(interval);

                                  if (interval < gameDuration) {
                                    setContestIntervalError(
                                      "value must be greater than or equal to gameDuration"
                                    );
                                  } else {
                                    setContestIntervalError(null);
                                  }
                                }}
                                InputProps={{
                                  inputProps: {
                                    min: gameDuration ?? 0,
                                  },
                                }}
                                error={!!error || !!contestIntervalError}
                                helperText={error ? error.message : contestIntervalError ?? null}
                                autoComplete="off"
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: "0px", // Adjust margin to prevent wrapping
                                    marginRight: "0px",
                                  },
                                  width: "11.5rem",
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>

                      {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                    </Box>

                    {/* GAME ASSET TYPE  */}
                    <GameForm
                      gameConfig={gameConfig}
                      control={control}
                      getValues={getValues}
                      setValues={setValue}
                      unregister={unregister}
                      error={error}
                      cryptos={cryptos}
                      stocks={stocks}
                    />

                    {/* <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "red" }}>
                        {error.cryptos ? error.cryptos : ""}
                      </Typography>
                      <Box mt={2} width={"100%"}>
                        <Controller
                          name="cryptos"
                          control={control}
                          defaultValue={null}
                          rules={{ required: "Crypto values are required" }}
                          render={({ field: { onChange } }) => (
                            <MultiSelect
                              items={cryptos}
                              label="Cryptos"
                              selectAllLabel="Select all cryptos"
                              onChange={onChange}
                            />
                          )}
                        />
                      </Box>
                    </Box> */}

                    {/* CREATE */}
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button variant="contained" type="submit" sx={{ textAlign: "center" }}>
                        Create
                      </Button>
                    </Box>
                  </Box>
                )}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* ALERT CREATE GAME */}
      <AlertConfirm
        open={alertOpen}
        title="Create"
        context="Do you want to create the game?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          try {
            if (Number(gameData?.entryFees) <= 0)
              return alert("Please select entry fees greater than zero!");

            const body = {
              ...gameData,
              fup: parseInt(gameData?.fup),
              entryFees: Number(gameData.entryFees),
              minPlayer: minPlayer,
              maxPlayer: maxPlayer,
              prizeMoney: prizeMoney,
              gamePaymentType: "Paid",
            };

            console.log(body, "BODY ");
            const gameCreate = await apiService.createGame(body);
            if (gameCreate) {
              alert("Success! New game has been created");
              setOpen(false);
              setTimeout(() => {
                window.location.reload();
              }, 300);
            }
          } catch (error) {
            console.log(error.response.data?.error, "Error");
            alert(error.response.data?.error);
            setAlertOpen(false);
          }
        }}
      />
    </div>
  );
}

export default GameCreate;
