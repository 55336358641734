/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
 * 
  All of the routes for the Crypto Champs React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Crypto Champs React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Users from "layouts/users";
// import Contests from "layouts/contests";
// import Crypto from "layouts/crypto";
// import CreateContest from "layouts/contests/create";
// import ContestType from "layouts/contesttype";
import CreateContestType from "layouts/contesttype/create";
// import AdminProfile from "layouts/profile/components/Password-reset";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// @mui icons
import Icon from "@mui/material/Icon";
import Games from "layouts/games";
import SignIn from "layouts/authentication/sign-in";
import Wallet from "layouts/wallet";
import Ledger from "layouts/ledger";
import AdminTransactionForm from "layouts/tokenDistribution";
import ProfileInfo from "layouts/profile/components/Profile";
import RewardTable from "layouts/tokenDistribution/Table";
import ViewInstances from "layouts/games/ViewInstances";
import RACBPage from "layouts/role/Admin";
import RoleAccessTable from "layouts/role/RoleAcessTable";
import Action from "layouts/role/Action";
import RoleView from "layouts/role/ViewRole";
import RoleEdit from "layouts/role/EditRole";
import UserEdit from "layouts/role/EditUser";
import BlockAccess from "layouts/blockAccess";
import GameType from "layouts/gameType";
import Merkle from "layouts/merkle";
import WalletData from "layouts/walletData";
import WalletConfiguration from "layouts/wallet-config";
import PayoutStructure from "layouts/payoutStructure";
import DepositeTransaction from "layouts/depositeTransaction";
import UsdcWithdraw from "layouts/usdcWithdraw";
// import HomeImageConfig from "layouts/HomeImgConfig";
import AdminWithdrawal from "layouts/withdrawal/AdminWithdrawal";
import SelectCryptos from "layouts/selectCryptos/SelectCryptos";
import AddCryptos from "layouts/selectCryptos/AddCryptos";
import Faq from "layouts/Faq/Faq";
import GameRules from "layouts/gameRules/GameRules";
import Referral from "layouts/referral/Referral";
import ReferralDetails from "layouts/referral/referralDetails";
import InActiveCryptos from "layouts/selectCryptos/InActiveCryptos";
import ShowNewUpdated from "layouts/selectCryptos/ShowNewUpdated";
import CustomUser from "layouts/customUser";
import SelectedStock from "../src/layouts/00_marketprice/stock/SelectStock";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Game Management",
    key: "game-management",
    icon: <Icon fontSize="small">gamepadIcon</Icon>,
    route: "/game-management",
    component: <Games />,
  },
  {
    type: "collapse",
    name: "USDC Withdraw",
    key: "USDC-Withdraw",
    icon: <Icon fontSize="small">wallet</Icon>,
    route: "/USDC-Withdraw",
    component: <UsdcWithdraw />,
  },
  {
    type: "collapse",
    name: "Select Cryptos",
    key: "select-cryptos",
    icon: <Icon fontSize="small">euro</Icon>,
    route: "/select-cryptos",
    component: <SelectCryptos />,
  },
  {
    type: "collapse",
    name: "Select Stocks",
    key: "select-stocks",
    icon: <Icon fontSize="small">euro</Icon>,
    route: "/select-stocks",
    component: <SelectedStock />,
  },
  {
    type: "collapse",
    name: "App Configuration",
    key: "app-configuration",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/app-configuration",
    component: <WalletConfiguration />,
  },

  {
    type: "collapse",
    name: "Wallet Data",
    key: "wallet-data",
    icon: <Icon fontSize="small">wallet</Icon>,
    route: "/wallet-data",
    component: <WalletData />,
  },

  {
    type: "collapse",
    name: "Access Location",
    key: "access-location",
    icon: <Icon fontSize="small">add_location</Icon>,
    route: "/access-location",
    component: <BlockAccess />,
  },

  {
    type: "collapse",
    name: "Merkle",
    key: "Merkle",
    icon: <Icon fontSize="small">book</Icon>,
    route: "/Merkle",
    component: <Merkle />,
  },

  {
    // type: "route",
    name: "Referral Details",
    key: "referralDetails",
    // icon: <Icon fontSize="small">wallet</Icon>,
    route: "/referralDetails/:id",
    component: <ReferralDetails />,
  },
  {
    key: "addcryptos",
    route: "/addcryptos",
    component: <AddCryptos />,
  },

  {
    key: "inActiveCryptos",
    route: "/inactive-cryptos",
    component: <InActiveCryptos />,
  },

  {
    key: "showNewUpdatedCrypto",
    route: "/shownew-updatedcrypto",
    component: <ShowNewUpdated />,
  },

  //{
  //   type: "collapse",
  //   name: "Home Page Config",
  //   key: "homepage-config",
  //   icon: <Icon fontSize="small">cropOriginalIcon</Icon>,
  //   route: "/homepage-config",
  //   component: <HomeImageConfig />,
  // },

  {
    type: "collapse",
    name: "Admin Withdrawal",
    key: "admin-withdrawal",
    icon: <Icon fontSize="small">gamepadIcon</Icon>,
    route: "/admin-withdrawal",
    component: <AdminWithdrawal />,
  },

  {
    type: "collapse",
    name: "Deposit Transactions",
    key: "deposit-transaction",
    icon: <AccountBalanceIcon fontSize="small" />,
    route: "/deposit-transaction",
    component: <DepositeTransaction />,
  },

  {
    type: "collapse",
    name: "Payout Structure",
    key: "payout-structure",
    icon: <Icon fontSize="small">receiptIcon</Icon>,
    route: "/payout-structure",
    component: <PayoutStructure />,
  },
  {
    type: "collapse",
    name: "Game Type",
    key: "game-type",
    icon: <Icon fontSize="small">sportsesports</Icon>,
    route: "/game-type",
    component: <GameType />,
  },
  {
    // type: "collapse",
    // name: "Game management",
    // key: "game-list",
    // icon: <Icon fontSize="small">gamepadIcon</Icon>,
    route: "/game-instance/",
    component: <ViewInstances />,
  },

  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Custom User",
    key: "custom-user",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: "/custom-user",
    component: <CustomUser />,
  },
  {
    type: "collapse",
    name: "User Role",
    key: "Role",
    icon: <Icon fontSize="small">verified_user</Icon>,
    route: "/Role",
    component: <RoleAccessTable />,
  },
  {
    type: "collapse",
    name: "Web User",
    key: "Web-User",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>,
    route: "/Web-User",
    component: <RACBPage />,
  },

  {
    key: "Action",

    route: "/Role/New",
    component: <Action />,
  },
  {
    route: "/Role/View/:id",
    component: <RoleView />,
  },
  {
    route: "/Role/Edit/:id",
    component: <RoleEdit />,
  },
  {
    route: "/WebUser/edit/:id",
    component: <UserEdit />,
  },

  {
    name: "Users edit",
    route: "/edit/users/:id",
    component: <ProfileInfo />,
  },
  {
    name: "distribution table",
    route: "/reward/table",
    component: <RewardTable />,
  },
  // {
  //   type: "collapse",
  //   name: "Contests",
  //   key: "contest-list",
  //   icon: <Icon fontSize="small">casino</Icon>,
  //   route: "/contests",
  //   component: <Contests />,
  // },

  // {
  //   name: "Createcontest",
  //   key: "Create Contest",
  //   icon: <Icon fontSize="small">Create Contest</Icon>,
  //   route: "/create-contest",
  //   component: <CreateContest />,
  // },
  // {
  //   type: "collapse",
  //   name: "Conest Type",
  //   key: "contest-type",
  //   icon: <Icon fontSize="small">border_all</Icon>,
  //   route: "/contest-type",
  //   component: <ContestType />,
  // },
  {
    name: "CreatecontestType",
    key: "Create Contest Type",
    icon: <Icon fontSize="small">Create Contest Type</Icon>,
    route: "/create-contest-type",
    component: <CreateContestType />,
  },

  {
    // type: "collapse",
    // name: "Profile",
    // icon: <Icon fontSize="small">person</Icon>,
    route: "/profile/:id",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Wallet Transaction",
    key: "wallet-transaction",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>,
    route: "/wallet-transaction",
    component: <Wallet />,
  },
  {
    type: "collapse",
    name: "Referral Program",
    key: "referral-program",
    icon: <Icon fontSize="small">room_preferences_icon</Icon>,
    route: "/referral-program",
    component: <Referral />,
  },
  {
    type: "collapse",
    name: "Reward",
    key: "reward",
    icon: <Icon fontSize="small">emoji_events</Icon>,
    route: "/reward",
    component: <AdminTransactionForm />,
  },
  {
    type: "collapse",
    name: "Ledger",
    key: "ledger",
    icon: <Icon fontSize="small">library_books</Icon>,
    route: "/ledger",
    component: <Ledger />,
  },
  {
    type: "collapse",
    name: "FAQ",
    key: "faq",
    icon: <Icon fontSize="small">library_books</Icon>,
    route: "/faq",
    component: <Faq />,
  },
  {
    type: "collapse",
    name: "Game Rules",
    key: "game-rules",
    icon: <Icon fontSize="small">library_books</Icon>,
    route: "/game-rules",
    component: <GameRules />,
  },
  {
    // type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    // type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    // type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },

  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
