import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import MultiSelect from "./MultiSelect";

const GameForm = ({
  gameConfig,
  control,
  getValues,
  setValues,
  unregister,
  error,
  cryptos,
  stocks,
  gameData,
}) => {
  const [gameAssetType, setGameAssetType] = useState(getValues("gameAssetType"));
  const defaultGameAssetType = gameData?.gameAssetType ?? gameConfig?.gameAssetType[0];
  // const defaultStocks = gameData?.stocks?.map((e) => e?._id) || [];

  useEffect(() => {
    if (!gameAssetType) {
      if (gameConfig?.gameAssetType?.length > 0) {
        setGameAssetType(gameConfig.gameAssetType[0]);
      }
    }
  }, [gameConfig]);

  if (!gameConfig) {
    return <>Loading...</>;
  }

  return (
    <>
      {/* GAME ASSET TYPE SELECT */}
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Controller
          name="gameAssetType"
          control={control}
          defaultValue={defaultGameAssetType || ""} // Default to the first value or empty string
          rules={{ required: "Game asset type is required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              sx={{ width: 180 }}
              defaultValue={value}
              options={gameConfig?.gameAssetType?.map((type) => ({ label: type })) || []} // Convert ["CRYPTOS", "STOCKS"] to [{ label: "CRYPTOS" }, { label: "STOCKS" }]
              getOptionLabel={(option) => option?.label || ""} // Display the label
              isOptionEqualToValue={(option, v) => option?.label === v?.label} // Match options by label
              onChange={(event, newValue) => {
                onChange(newValue?.label || ""); // Update React Hook Form's value
                setValues("stocks", []);
                setValues("cryptos", []);

                if (newValue?.label == "CRYPTOS") {
                  unregister("stocks");
                } else if (newValue?.label == "STOCKS") {
                  unregister("cryptos");
                }

                setGameAssetType(newValue?.label);
              }}
              value={
                gameConfig?.gameAssetType
                  ?.map((type) => ({ label: type }))
                  ?.find((option) => option.label === value) || null // Ensure value matches the option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Game Asset Type"
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />
      </Box>

      {/* SELECT ALL CRYPTOS OR STOCKS */}
      {gameAssetType && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography variant="body2" sx={{ color: "red" }}>
            {error.value ? error.value : ""}
          </Typography>
          <Box mt={2} width={"100%"}>
            {gameAssetType == "CRYPTOS" && (
              <Controller
                name={"cryptos"}
                control={control}
                defaultValue={gameData?.cryptos || []}
                rules={{
                  required: `${"Crypto values are required"}`,
                }}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    value={value}
                    items={cryptos}
                    label={"Cryptos"}
                    selectAllLabel={"Select all cryptos"}
                    onChange={onChange}
                  />
                )}
              />
            )}

            {gameAssetType == "STOCKS" && (
              <Controller
                name={"stocks"}
                control={control}
                defaultValue={gameData?.stocks || []}
                rules={{
                  required: `${"Stocks values are required"}`,
                }}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    value={value}
                    items={stocks}
                    label={"Stocks"}
                    selectAllLabel={"Select all stocks"}
                    onChange={onChange}
                  />
                )}
              />
            )}
            {/* <Controller
              name={gameAssetType === "CRYPTOS" ? "cryptos" : "stocks"}
              control={control}
              defaultValue={null}
              rules={{
                required: `${
                  gameAssetType === "CRYPTOS"
                    ? "Crypto values are required"
                    : "Stocks values are required"
                }`,
              }}
              render={({ field: { onChange } }) => (
                <MultiSelect
                  items={gameAssetType === "CRYPTOS" ? cryptos : stocks}
                  label={gameAssetType === "CRYPTOS" ? "Cryptos" : "Stocks"}
                  selectAllLabel={
                    gameAssetType === "CRYPTOS" ? "Select all cryptos" : "Select all stocks"
                  }
                  onChange={onChange}
                />
              )}
            /> */}
          </Box>
        </Box>
      )}
    </>
  );
};

// Define prop types
// GameForm.propTypes = {
//   gameConfig: PropTypes.object.isRequired, // Array is expected
//   control: PropTypes.object.isRequired, // Object is expected
// };

export default GameForm;
